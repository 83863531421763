import React, { useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"
import heart from "../assets/images/pt-heart-icon.svg"

const query = graphql`
  {
    strapiMenus {
      Navbar {
        data {
          color
          id
          text
          url
          children {
            id
            text
            url
          }
        }
      }
    }
  }
`

const SubNavigation = ({ styleClass }) => {
  const [pathName, setPathName] = React.useState(false)
  const { strapiMenus } = useStaticQuery(query)
  const tempLinks =
    strapiMenus.Navbar.data.find(x=>x.url === '/').children.map(link => {
      const itemClassNames = classNames({
        last: strapiMenus.Navbar.data.length === link.id,
        currentPage: pathName === link.url
      })
      // const hasHeartIcon = link.id === 3
      return (
        <li key={link.id} className={itemClassNames}>
              <Link to={link.url}>
                <span style={{ verticalAlign: "top" }}>{link.text}</span>
                {/* {hasHeartIcon && <img src={heart} alt="heart-icon" />} */}
              </Link>
        </li>
      )
    })


  useEffect(() => {
    const path = window.location.pathname.split('/')
    if(path.length > 3) {
      setPathName('/'+path[1]+'/'+path[2])
    } else {
      setPathName(path.join('/'))
    }
  }, [pathName])

  return (
    <ul className={classNames("page-links", styleClass)}>{tempLinks}</ul>
  )
}

export default SubNavigation
