import React from 'react'
import emblem from '../assets/images/pt-emblem.svg'
import heart from '../assets/images/pt-heart-icon.svg'
import yifLogo from '../assets/images/yusuf-islam-f-b-w.svg'
import csLogo from '../assets/images/cs-logo.png'
import facebook from '../assets/images/facebook-logo.svg'
import twitter from '../assets/images/twitter-logo.svg'
import instagram from '../assets/images/instagram-logo.svg'
import { Link } from "gatsby"

const Footer = () => {
    return (
        <footer>
            <div className="footer">
                <div className="footer-first-item">
                    {/* <div className="footer-center">
                        <div className="empty-div" />
                        <div className="empty-div" />
                    </div> */}
                    {/* <div className="footer-center">
                        <div className="empty-div" />
                        <div className="footer-right-bottom-first">
                            <p>If you would like to</p>
                            <Link to="/contribute?num=1" className="btn btn-primary footer-contribute-button">
                                <span>
                                    contribute
                                </span>
                                <span style={{ alignSelf: "end" }}>
                                    <img width={27} height={27} src={heart} alt="heart-icon" />
                                </span>
                            </Link>
                        </div>
                    </div> */}
                    <div className="footer-center">
                        <div className="empty-div" />
                        <div className="empty-div" />
                    </div>
                    <div className="footer-center">
                        <div className="footer-left">
                            <div className="footer-socialIcons">
                                <div className="footer-logo">
                                    <img
                                        src={emblem}
                                        alt="pt-emblem"
                                        width={132.703}
                                        height={42.766}
                                    />
                                </div>
                                <div>
                                    <p className="footer-emblem-slogan">“the world as one”</p>
                                </div>
                                {/* <div>
                                    <a href="https://twitter.com/peacetrainorg" rel="noreferrer" target="_blank"><img src={twitter} alt="twitter" height="32vh" /></a>
                                    <a href="https://facebook.com/Peacetrainorg" rel="noreferrer" target="_blank"><img src={facebook} alt="facebook" height="32vh" /></a>
                                    <a href="https://instagram.com/peacetrainofficial" rel="noreferrer" target="_blank" className="footer-socialIcons-instagram"><img src={instagram} alt="instagram" height="32vh" /></a>
                                </div> */}
                            </div>
                            <p className="footer-comment footer-second-comment">Yusuf Islam Foundation is a UK registered charity 1134513 and UK registered company 07055355 <br /> Registered Address: Yusuf Islam Foundation, The Maqam, Tiverton Road, London, NW10 3HJ</p>
                        </div>
                        <div className="footer-right">
                            <div className="footer-right-bottom-second">
                                <div className="footer-right-bottom-second-firstColumn">
                                    <img src={yifLogo} alt="Yusuf Islam Foundation"></img>
                                    <p style={{ marginBottom: 0, marginTop: 6 }}>Peace Train is a
                                        <br />Yusuf Islam Foundation
                                        <br />Project
                                    </p>
                                </div>
                                <div className="footer-right-bottom-second-secondColumn">
                                    <p style={{ marginTop: 0}}>Under the Patronage of</p>
                                    <img src={csLogo} alt="Catstevens" height="74vh"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-center">
                        <div className="empty-div" />
                        <div className="empty-div" />
                    </div>
                </div>
            </div>
            <div className="footer-terms-of-use">
                <p><Link to="/privacy-policy">Privacy Policy</Link> | © 2021 Yusuf Islam Foundation</p>
            </div>
        </footer>
    )
}

export default Footer
